<template>
  <div class="page-activity" v-wechat-title="'生态伙伴招募'">
    <div class="page-activity-box">
      <div class="page-activity-title">
        <strong>万物智联·共创共荣</strong>
        <p> ——生态伙伴招募计划即日开始！</p>
      </div>
      <div class="page-activity-info">
        <div>
          <img src="@/assets/img/L@2s.png" alt="图片" class="activity-icon1">
          <img src="@/assets/img/L@2xx.png" alt="图片" class="activity-icon2">
          数字浪潮，方兴未艾。智能时代，触手可及。随着新一代信息技术进步，智联网的发展迎来新的机遇，万物智联仍需万千生态伙伴共同聚力，携手创新。中软国际愿意与广大开发端、品牌端、市场端行业合作伙伴，共建AIoT智能商业生态，撬动全球万亿市场。
        </div>
        <strong>我们助力</strong>
        <p><span></span> 线上产业生态平台提供海量供需合作机会</p>
        <p><span></span> 汇聚海量开源系统组件助力高效研发</p>
        <p><span></span> 线下赋能中心提供超多专业培训、共创成长机会</p>
        <p><span></span> 汇聚海量模组、SOC方案板、多云平台助力智能化</p>
        <p><span></span> 软件工厂提供产品研发一站式高效交付服务</p>
        <strong>伙伴权益</strong>
        <span>技术支持</span>
        <p><span></span> 开源鸿蒙国产替代技术免费咨询</p>
        <p><span></span> 开源鸿蒙国产开发套件免费申请</p>
        <span>产业赋能</span>
        <p><span></span> 生态平台行业物联网场景需求对接</p>
        <p><span></span> 赋能平台开源技术赋能降低企业研发成本</p>
        <span>政策培训</span>
        <p><span></span> 区域数字化转型政策辅导</p>
        <p><span></span> 数字化转型技术人才培训</p>
        <strong>招募对象</strong>
        <p>1、物联网硬件设计开发商 </p>
        <p>2、物联网生产制造服务商</p>
        <p>3、产品认证检测服务商</p>
        <p>4、行业解决方案集成商</p>
        <p>5、各领域专业能力供应商</p>
        <strong>加入流程</strong>
        <div>
          <img src="@/assets/img/5238@2x.png" alt="图片" class="page-activity-step-img">
        </div>
      </div>
      <div class="page-activity-connect">
        <span><img src="@/assets/img/qywx.png" alt="图片"></span>
        <div>
          <span>联系我们</span>
          <p><img src="@/assets/img/5115@2x.png" alt="图片">400-025-6877</p>
          <p><img src="@/assets/img/5116@2x.png" alt="图片">IIG.RDD.honglian@chinasofti.com</p>
          <p><img src="@/assets/img/5235@2x.png" alt="图片">扫码添加企业微信</p>
        </div>
      </div>
    </div>
    <div class="page-activity-float">
      <div>
        <p>限时免费获取开发板</p>
        <span>加入后工作人员将尽快与您联系</span>
      </div>
      <van-button @click="register">立即加入</van-button>
    </div>
    <share :val="info"/>
  </div>
</template>

<script>
import share from '../components/share.vue'
export default {
  components: {
    share
  },
  data () {
    return {
      info: {
        title: '生态伙伴招募',
        desc: '万物智联·共创共荣 ——生态伙伴招募计划即日开始！',
        img: require('@/assets/img/logoinimg.png')
      }
    }
  },
  methods: {
    register () {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-activity{
  padding-bottom: 64px;
  .page-activity-box{
    background: url(../../assets/img/background@2x.png) no-repeat;
    background-size: 100%;
    padding: 56px 16px 0;
    .page-activity-title{
      margin-bottom: 150px;
      >strong{
        font-size: 28px;
        line-height: 37px;
        color: #EA0B06;
        display: block;
        margin-bottom: 16px;
      }
      >p{
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #EA0B06;
      }
    }
    .page-activity-info{
      background: url(../../assets/img/background1@2x.png) no-repeat;
      background-size: 100%;
      padding: 24px 16px;
      >div{
        font-size: 12px;
        color: #666;
        line-height: 18px;
        position: relative;
        padding-bottom: 9px;
        .activity-icon1{
          position: absolute;
          top: -9px;
          left: -9px;
          width: 30px;
          height: 30px;
          z-index: 2;
        }
        .activity-icon2{
          position: absolute;
          bottom: 0;
          left: 142px;
          width: 30px;
          height: 20px;
          z-index: 2;
        }
        .page-activity-step-img{
          display: block;
          width: 210px;
          margin: 0 auto;
        }
        >span{
          position: absolute;
          display: block;
          width: 50px;
          text-align: center;
          line-height: 18px;
          color: #fff;
          font-size: 12px;
          &:nth-child(2n+1){
            left: 74px;
          }
          &:nth-child(2n){
            right: 74px;
          }
        }
      }
      >strong{
        display: block;
        width: 120px;
        height: 36px;
        background: url(../../assets/img/5216@2x.png) no-repeat;
        background-size: 100%;
        margin: 24px 0 16px;
        font-size: 16px;
        color: #fff;
        line-height: 32px;
        padding-left: 14px;
      }
      >span{
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        margin-top: 8px;
      }
      >p{
        font-size: 12px;
        line-height: 18px;
        color: #666;
        display: flex;
        align-items: center;
        margin-top: 8px;
        >span{
          width: 3px;
          height: 3px;
          background: #999999;
          margin-right: 6px;
        }
      }
    }
    .page-activity-connect{
      padding: 32px 0;
      display: flex;
      >span{
        width: 95px;
        height: 95px;
        margin-right: 12px;
        >img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      >div{
        flex: 1;
        >span{
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
        }
        >p{
          margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 17px;
          color: #333333;
          >img{
            width: 16px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .page-activity-float{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 64px;
    background: linear-gradient(90deg, #E2824A 0%, #E7131B 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    >div{
      color: #fff;
      >p{
        height: 22px;
        font-size: 16px;
        line-height: 22px;
      }
      >span{
        display: block;
        font-size: 12px;
        line-height: 17px;
      }
    }
    >button{
      width: 99px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 2px;
      color: #EA0B06;
      font-size: 12px;
    }
  }
}
</style>
